import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PrivacyPolicy from '../components/PrivacyPolicy/privacy-policy';

const PrivacyPolicyPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Privacy Policy" />
    <PrivacyPolicy />
  </Layout>
);

PrivacyPolicyPage.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default PrivacyPolicyPage;
